import { platformizedPostMapper } from '@wix/communities-blog-wix-code-common';
import urlService from './url';
import getQuery from './get-query';

export const fieldsToIncludeQuery = [
  'CONTENT_TEXT',
  'URL',
  'METRICS',
  'INTERNAL_ID',
]
  .map((field) => `fieldsToInclude=${field}`)
  .join('&');

const getPlatformizedPostBySlug = async ({
  instance,
  slug,
  wixCodeApi,
  request,
  isPostPageApiFixExperimentEnabled,
}) => {
  try {
    const response = await request(
      `/posts/slugs/${slug}?${fieldsToIncludeQuery}`,
      {
        baseUrl: urlService.getAPIUrl(
          wixCodeApi,
          isPostPageApiFixExperimentEnabled,
        ),
        instance,
      },
    );

    return platformizedPostMapper.mapRest(response.post);
  } catch (err) {
    return;
  }
};

const getPlatformizedPostById = async ({
  instance,
  id,
  wixCodeApi,
  request,
  isPostPageApiFixExperimentEnabled,
}) => {
  try {
    const response = await request(`/posts/${id}?${fieldsToIncludeQuery}`, {
      baseUrl: urlService.getAPIUrl(
        wixCodeApi,
        isPostPageApiFixExperimentEnabled,
      ),
      instance,
    });
    return platformizedPostMapper.mapRest(response.post);
  } catch (err) {
    return;
  }
};

const getPlatformizedPosts = async ({
  instance,
  params,
  wixCodeApi,
  request,
  isPostPageApiFixExperimentEnabled,
}) => {
  try {
    const response = await request(
      `/posts?${getQuery(params)}&${fieldsToIncludeQuery}`,
      {
        baseUrl: urlService.getAPIUrl(
          wixCodeApi,
          isPostPageApiFixExperimentEnabled,
        ),
        instance,
      },
    );
    return response.posts
      ? response.posts.map(platformizedPostMapper.mapRest)
      : [{}];
  } catch (err) {
    return;
  }
};
const getPlatformizedLastPost = ({
  instance,
  wixCodeApi,
  request,
  isPostPageApiFixExperimentEnabled,
}) =>
  getPlatformizedPosts({
    instance,
    params: { 'paging.limit': 1 },
    wixCodeApi,
    request,
    isPostPageApiFixExperimentEnabled,
  }).then((posts) => posts[0]);

export default {
  getPlatformizedPostBySlug,
  getPlatformizedLastPost,
  getPlatformizedPostById,
};

import { PLATFORMISED_API_URL } from '../constants/api';
import getEnvironment from './get-environment';

const getAPIUrl = (wixCodeApi, isPostPageApiFixExperimentEnabled) => {
  if (wixCodeApi.window.rendering.env !== 'backend') {
    const { isPreview } = getEnvironment(wixCodeApi);
    if (isPostPageApiFixExperimentEnabled && !isPreview) {
      return `${wixCodeApi.location.baseUrl}${PLATFORMISED_API_URL}`;
    }
    return PLATFORMISED_API_URL;
  }

  const baseUrl = wixCodeApi.location.baseUrl;
  if (baseUrl.includes('wixsite.com')) {
    return `${baseUrl.substring(
      0,
      baseUrl.lastIndexOf('/'),
    )}${PLATFORMISED_API_URL}`;
  }

  return `${baseUrl.replace(
    /(https?:\/\/[^/]+)\/.+/,
    '$1',
  )}${PLATFORMISED_API_URL}`;
};

export default {
  getAPIUrl,
};
